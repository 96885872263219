import { useState, useEffect, ReactElement, ReactNode } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import 'reactjs-popup/dist/index.css';
import type { NextPage } from 'next';
import { DefaultSeo } from 'next-seo';
import { AppProps } from 'next/app';
import Head from 'next/head';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { COOKIES_CONFIG, RouterProvider } from '@belong/common';
import { Footer } from '@belong/ui';
import '@belong/ui/index.css';
import '@reach/accordion/styles.css';
import '@reach/dialog/styles.css';
import '@reach/listbox/styles.css';
import '@reach/slider/styles.css';
import '@reach/tabs/styles.css';
import 'react-day-picker/dist/style.css';
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import { parseCookies } from 'nookies';
import { Header } from 'src/components/header/header';
import { NProgress } from 'src/components/nprogress/nprogress';
import { ClientInit } from 'src/features/app/client-init';
import { PrismicPreview } from 'src/features/cms/components/prismic-preview';
import { DataDog } from 'src/features/document/data-dog';
import { Fullstory } from 'src/features/document/fullstory';
import { GoogleAnalytics } from 'src/features/document/google-analytics';
// import { GoogleOptimize } from 'src/features/document/google-optimize';
import { GoogleTagManager } from 'src/features/document/google-tag-manager';
import { Mixpanel } from 'src/features/document/mixpanel';
import { ModalManager } from 'src/modals/modal-manager/modal-manager';
import { useCreateStore, Provider } from 'src/store';
import { isProd } from 'src/utils/is-prod';
import { pageview } from 'src/utils/tracking';
import '../styles/globals.css';

console.log('Build Number:', process.env.NEXT_PUBLIC_BUILD_NUMBER);

const gtmView = process.env.NEXT_PUBLIC_GTM_VIEW;
const gtmId = process.env.NEXT_PUBLIC_GTM_ID;
const gaId = process.env.NEXT_PUBLIC_GA_ID;
const ga4Id = process.env.NEXT_PUBLIC_GA4_ID;

// const optimizeId = process.env.NEXT_PUBLIC_OPTIMIZE_ID;

export function getDefaultLayout(page: ReactNode): ReactNode {
  const cookies = parseCookies();
  const comeFromMobileApp = Boolean(cookies[COOKIES_CONFIG.MOBILE_APP.name] === 'true');

  return (
    <div className="flex flex-col min-h-full">
      {!comeFromMobileApp && <Header />}
      <main className="flex-1">{page}</main>
      {!comeFromMobileApp && <Footer />}
    </div>
  );
}

function CustomApp({ Component, pageProps }: CustomAppProps) {
  const router = useRouter();
  // @ts-ignore
  const createStore = useCreateStore(pageProps.initialZustandState);
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: 10000,
            retry: isProd,
            refetchOnWindowFocus: isProd,
          },
        },
      })
  );

  useEffect(() => {
    NProgress.configure({ trickleSpeed: 200 });

    function routeChangeStart(_: string, { shallow }: { shallow: boolean }) {
      window.mixpanel?.register({
        'Previous URL': router.asPath,
      });

      if (router.isReady && !shallow) {
        NProgress.start();

        window.Intercom?.('update', {
          hide_default_launcher: true,
        });
      }
    }

    function routeChangeComplete(url: string, { shallow }: { shallow: boolean }) {
      if (router.isReady) {
        pageview(url);

        if (!shallow) {
          NProgress.done();

          // Show Intercom on accounts pages
          if (router.asPath.includes('/accounts')) {
            window.Intercom?.('update', {
              hide_default_launcher: false,
            });
          }
        }
      }
    }

    function routeChangeError(_: string, { shallow }: { shallow: boolean }) {
      if (router.isReady && !shallow) {
        NProgress.done();
      }
    }

    router.events.on('routeChangeStart', routeChangeStart);
    router.events.on('routeChangeComplete', routeChangeComplete);
    router.events.on('routeChangeError', routeChangeError);

    return () => {
      router.events.off('routeChangeStart', routeChangeStart);
      router.events.off('routeChangeComplete', routeChangeComplete);
      router.events.off('routeChangeError', routeChangeError);

      window.Intercom?.('hide');
      window.Intercom?.('update', {
        hide_default_launcher: true,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady]);

  const getLayout = Component.getLayout || getDefaultLayout;

  return (
    <QueryClientProvider client={queryClient}>
      <Provider createStore={createStore}>
        <RouterProvider link={Link} router={router}>
          <PrismicPreview repositoryName="belongtest">
            <ClientInit>
              {getLayout(
                <>
                  {/* https://nextjs.org/docs/messages/no-document-viewport-meta */}
                  <Head>
                    <meta name="viewport" content="width=device-width, initial-scale=1, viewport-fit=cover" />
                  </Head>

                  {/* Twitter will read the og:title, og:image and og:description tags for their card,
                        this is why next-seo omits twitter:title, twitter:image and twitter:description so not to duplicate.
                        */}
                  <DefaultSeo
                    titleTemplate="%s"
                    defaultTitle="Renting People Love | For Homeowners and Residents"
                    description="Renting is the new American lifestyle. We didn't create it. But we're making it sing."
                    canonical="https://belonghome.com/"
                    facebook={{
                      appId: '1906883602682636',
                    }}
                    twitter={{
                      site: '@joinbelong',
                      cardType: 'summary_large_image',
                    }}
                    openGraph={{
                      type: 'website',
                      url: 'https://belonghome.com/',
                      site_name: 'Belong',
                      title: 'Renting People Love | For Homeowners and Residents',
                      description:
                        "Renting is the new American lifestyle. We didn't create it. But we're making it sing.",
                      images: [
                        {
                          url: 'https://s3-us-west-2.amazonaws.com/belong-web-assets/belong_og_image.jpg',
                          alt: 'The magical world of Belong.',
                          width: 1200,
                          height: 630,
                        },
                      ],
                    }}
                  />

                  {gtmId && <GoogleTagManager gtmId={gtmId} gtmView={gtmView} />}
                  {gaId && <GoogleAnalytics gaId={gaId} ga4Id={ga4Id} />}
                  {/* {optimizeId && <GoogleOptimize optimizeId={optimizeId} />} */}
                  <Mixpanel />

                  <Component {...pageProps} />
                  <ModalManager />
                  <DataDog />
                  {isProd && <Fullstory />}
                </>,
                {
                  ...pageProps,
                }
              )}
            </ClientInit>
          </PrismicPreview>
        </RouterProvider>
      </Provider>
    </QueryClientProvider>
  );
}

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement, pageProps?: unknown) => ReactNode;
};

type CustomAppProps = AppProps & {
  Component: NextPageWithLayout;
  pageProps: unknown;
};

export default CustomApp;
